<template>
  <section>
    <div class="grid grid-cols-3 gap-5 mx-5">
      <div class="flex flex-col ">
        <span class="font-bold">Año </span>
        <InputText class="rounded w-full border uppercase" type="number" v-model="model.Anio" />
        <MessageError :text="errors.Anio"/>
      </div>
      <div class="flex flex-col ">
        <span class="font-bold">Regimen</span>
        <!-- <InputText class="rounded w-full border uppercase" type="text" v-model="model.U_PHR_Regimen" /> -->
        <Dropdown v-model="model.U_PHR_Regimen" :options="Regimenes" optionLabel="label" optionValue="value"
                  placeholder="Seleccione un tipo"/>
        <MessageError :text="errors.U_PHR_Regimen"/>
      </div>
      <div class="flex flex-col ">
        <span class="font-bold">Codigo </span>
        <InputText class="rounded w-full border uppercase" type="text" v-model="model.Code" />
        <MessageError :text="errors.Code"/>
      </div>
      <div class="flex flex-col ">
        <span class="font-bold">Rango Salarial </span>
        <InputText class="rounded w-full border uppercase" type="text" v-model="model.U_PHR_RangSalarial" />
        <MessageError :text="errors.U_PHR_RangSalarial"/>
      </div>
      <div class="flex flex-col ">
        <span class="font-bold">% Cobro </span>
        <InputText class="rounded w-full border uppercase" type="number" v-model="model.U_PHR_PorCobro" />
        <MessageError :text="errors.U_PHR_PorCobro"/>
      </div>
      <div class="flex flex-col ">
        <span class="font-bold">Tp Max Nivel </span>
        <InputText class="rounded w-full border uppercase" type="number" v-model="model.U_PHR_TpMxNivel" />
        <MessageError :text="errors.U_PHR_TpMxNivel"/>
      </div>
      <div class="flex flex-col ">
        <span class="font-bold">Valor cuota </span>
        <InputText class="rounded w-full border uppercase" type="number" v-model="model.U_PHR_ValorCuota" />
        <MessageError :text="errors.U_PHR_ValorCuota"/>
      </div>
      <div class="flex flex-col ">
        <span class="font-bold">Tope Max Rango </span>
        <InputText class="rounded w-full border uppercase" type="number" v-model="model.U_PHR_TopeMaxRango" />
        <MessageError :text="errors.U_PHR_TopeMaxRango"/>
      </div>
    </div>
    <div class="w-full flex justify-end mt-5 ">
      <Button label="Cancelar"
              class="bg-blue-900 p-button-outlined"
              @click="resetFilters"
      />
      <Button label="Guardar"
              class="bg-blue-900 mx-5 px-5"
              type="button"
              @click="onSubmit"
      />
    </div>
  </section>
</template>
<script setup>
import { ref, onMounted, defineEmits, defineProps } from 'vue'
import { useField, useForm } from 'vee-validate'
import Swal from 'sweetalert2'
import * as yup from 'yup'
import TasaService from '../../../../../services/calculo-cuota.service'

// instancias
const _TasaService = ref(new TasaService())
const emit = defineEmits(['customChange'])
const props = defineProps({
  idTasa: String
})
const Regimenes = ref([{ value: 'S', label: 'Subsidiado' }, { value: 'C', label: 'Contributivo' }])
// referencias

const validationSchema = yup.object({
  U_PHR_Regimen: yup.string().required('El regimen es requerido').label(''),
  U_PHR_RangSalarial: yup.string().required('El rango es requerido').label(''),
  U_PHR_PorCobro: yup.string().required('El porcentaje es requerido').label(''),
  U_PHR_TpMxNivel: yup.string().required('El nivel es requerido').label(''),
  U_PHR_ValorCuota: yup.string().required('La cuota es requerido').label(''),
  U_PHR_TopeMaxRango: yup.string().required('El tope es requerido').label('')
})

const { errors, handleSubmit, values: model, handleReset } = useForm({ validationSchema })

  useField('U_PHR_Regimen', null, { initialValue: '' })
  useField('U_PHR_RangSalarial', null, { initialValue: '' })
  useField('U_PHR_PorCobro', null, { initialValue: '' })
  useField('U_PHR_TpMxNivel', null, { initialValue: '' })
  useField('U_PHR_ValorCuota', null, { initialValue: '' })
  useField('U_PHR_TopeMaxRango', null, { initialValue: '' })

const onSubmit = handleSubmit(async (values) => {
    if (!values) throw new Error('Error de validación')
    for (const [index, item] of Object.entries(model)) {
      if (typeof item === 'string') {
        model[index] = item.toUpperCase()
      }
    }
    if (props.idTasa) {
      _TasaService.value.editarTasa(model).then((res) => {
        handleReset()
        Swal.fire({
          icon: 'success',
          title: 'Tasa Actualizada',
          text: 'La Tasa se ha actualizado correctamente'
        }).then(() => {
          emit('crearTasa')
        })
      })
    } else {
      _TasaService.value.crearTasa(model).then((res) => {
        handleReset()
        Swal.fire({
          icon: 'success',
          title: 'Tasa creada',
          text: 'La Tasa se ha creado correctamente'
        }).then(() => {
          emit('crearTasa')
        })
      })
    }
})
const resetFilters = () => {
  model.value = {
    Code: '',
    Name: ''
  }
}

onMounted(() => {
  if (props.idTasa) {
    _TasaService.value.getTasa(props.idTasa).then(async ({ data }) => {
      for (const [index, item] of Object.entries(data)) {
        model[index] = item
      }
    })
  }
})
</script>
<style>
</style>
